type CommonMetaTags = { baseUrl?: string; title?: string; description?: string }

function formatTitle(title?: string) {
  const defaultTitle =
    'Lemon Energia | Energia sustentável, digital e mais barata pro seu negócio.'

  return title ? `${title} | Lemon Energia` : defaultTitle
}

function formatDescription(description?: string) {
  const defaultDescription =
    'Com a ajuda da Lemon, você sempre - e é sempre mesmo - vai ter energia limpa e economia para o seu negócio. Tudo digital!'

  return description ?? defaultDescription
}

const commonMetaTags = ({ baseUrl, title, description }: CommonMetaTags) => {
  title = formatTitle(title)
  description = formatDescription(description)
  return [
    {
      title,
    },
    {
      name: 'description',
      content: description,
    },

    // facebook
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: `${baseUrl}/social-media-thumb.png`,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'facebook-domain-verification',
      content: 'anc5bcvuwcmovh713vthw6nhcbjp5n',
    },

    // twitter
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:image',
      content: `${baseUrl}/social-media-thumb.png`,
    },
  ]
}

export default commonMetaTags
